import { useMutation, useQueryClient } from "@tanstack/react-query";
import { authorizedAxios } from "@/axios";
import { useAuth, useProfileQueryKey } from "@moonscape/shared";

export const useToggleDemoMode = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (isDemo: boolean) => {
      const token = await getToken();
      const response = await authorizedAxios(token).post(
        `/profile/demo?demo=${isDemo}`
      );

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(); // all queries
    },
  });
};
