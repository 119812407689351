import React from "react";
import { ScreenHeader } from "./ScreenHeader";
import { MobileNavigation } from "./common";
import { useProfile } from "@moonscape/shared";
import { Link, useRouteMatch } from "react-router-dom";

import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";

export const Screen = ({
  children,
  heading,
  className,
}: {
  children: React.ReactNode;
  heading?: string;
  className?: string;
}) => {
  const { profile } = useProfile();
  const isDemo = profile?.isDemo;
  const { path, url } = useRouteMatch();

  // Add check for exchange settings route
  const isExchangeSettingsRoute = path === "/settings";

  console.log("[profile] isDemo", profile);
  return (
    <div className={`flex-1 flex-col overflow-auto ${className}`}>
      <div
        className={`min-h-screen flex overflow-auto  bg-gray-100 ${className}`}
      >
        <div className={`flex-1 overflow-auto focus:outline-none ${className}`}>
          <MobileNavigation />
          {/* <NavBar /> */}
          <ScreenHeader heading={heading} />
          {isDemo && !isExchangeSettingsRoute && (
            <div className="bg-green-500 border-b border-green-200">
              <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8 py-3">
                <div className="flex items-center justify-between flex-wrap">
                  <div className="w-0 flex-1 flex items-center flex-col">
                    <p className="ml-3 pt-6 text-md text-gray-900">
                      <span className="text-2xl font-semibold">
                        You're looking at demo data.
                      </span>
                    </p>
                    <p className="ml-3 pt-2 pb-4 text-md text-gray-800">
                      <span className="text-lg font-medium">
                        Why not see the real thing?
                      </span>
                    </p>
                    <Link to="/settings/exchange/new" className="pb-4">
                      <button
                        type="button"
                        className="rounded-full bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Connect exchange
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={`max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8 ${className}`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
