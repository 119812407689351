import React, { useCallback, useEffect } from "react";
import { ProtectedRoute } from "@/auth";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { page as analyticsPage } from "@/analytics";
import { useAuth0 } from "@auth0/auth0-react";

import {
  CoinbaseAuth,
  Login,
  Logout,
  Profile,

  // stripe
  StripeCancel,
  StripeCheckout,
  StripeSuccess,
  FeedbackScreen,
  ImportScreen,
} from "@/components";
import {
  LandingScreen,
  PositionsScreen as PortfolioScreen,
  PositionsScreen,
  SettingsScreen,
  TaxScreen,
  ProTaxScreen,
  TransactionAuditScreen,
  RawTransactionsScreen,
  TaxSafeHarborScreen,
  OnboardingModal,
  QuickStartScreen,
} from "@/screens";

import { useFeatureFlag, FEATURE_FLAG_KEYS } from "@moonscape/shared";

import { VerifyEmail } from "./components/VerifyEmail";
import { UpgradeScreen } from "./screens/UpgradeScreen";

/** to get it to only render once */
const CoinbaseAuthRouteWrapper = () => {
  const renderCoinbaseAuth = useCallback(() => <CoinbaseAuth />, []);

  return <Route exact path="/coinbase-auth" render={renderCoinbaseAuth} />;
};
export const Routes = () => {
  // analytics
  const location = useLocation();

  // TODO: remove - Hardcoded for now - will come from useProfile later
  const isFirstLoad = true;

  const showIAP = useFeatureFlag(FEATURE_FLAG_KEYS.WEB_SHOW_IAP);

  console.log("web showIAP", showIAP);
  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    // console.log("[analytics] route has been changed", location?.pathname);
    // segment analytics

    if (isAuthenticated && !isLoading) {
      analyticsPage("navigate", location?.pathname);
    }
    // window.gtag("event", "page_view", {
    //   page_path: location.pathname + location.search,
    // });
  }, [isAuthenticated, isLoading, location?.pathname, location.search]);

  return (
    <Switch>
      <ProtectedRoute exact component={QuickStartScreen} path="/quickstart" />
      <ProtectedRoute exact component={LandingScreen} path="/" />

      <ProtectedRoute
        exact
        component={LandingScreen}
        path="/transaction/:extTxnId"
      />
      {/* root */}
      {/* don't have `exact` prop on settings bc it won't match and do to the sub-routes */}
      <ProtectedRoute path="/settings" component={SettingsScreen} />
      {/* <ProtectedRoute exact path="/watch" component={Watch} returnTo="/watch" /> */}
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/verify-email">
        <VerifyEmail />
      </Route>
      {/* You can add a new ProtectedRoute for /tax/pro before the existing ProtectedRoute for /tax. The order of routes is important in react-router-dom.  */}
      <ProtectedRoute path="/tax/pro" component={ProTaxScreen} />
      <ProtectedRoute path="/tax/safe-harbor" component={TaxSafeHarborScreen} />
      <ProtectedRoute path="/tax" component={TaxScreen} />
      {/* go to login with /:plan params in the url  */}
      <Route path="/signup/:plan" component={Login} />
      {/* optional parameters to filter down on exchange/coin */}
      {/* <ProtectedRoute
        exact
        path="/trades"
        // path="/trades/:?exchange/:asset?"
        component={TradesScreen}
      /> */}
      <ProtectedRoute
        path="/portfolio/:exchange?/:asset?"
        component={PortfolioScreen}
      />
      <ProtectedRoute path="/positions" component={PositionsScreen} />
      {/* 
      /portfolio/:exchange/:coin
      e.g.
      /portfolio/all/BTC
      /portfolio/binance/DOGE
      /portfolio/coinbase/

      */}
      {/* <ProtectedRoute exact path="/portfolio" component={PortfolioScreen} /> */}
      <Route exact path="/profile">
        <Profile />
      </Route>
      <Route exact path="/logout">
        <Logout />
      </Route>
      {showIAP && (
        <Route exact path="/plans">
          {/* <UpgradeScreen /> */}
          <StripeCheckout />
        </Route>
      )}
      {/* <ProtectedRoute
        exact
        component={LandingScreen}
        path="/transaction/:extTxnId"
      /> */}
      <ProtectedRoute
        path="/transactions/:exchange"
        component={RawTransactionsScreen}
      />
      <ProtectedRoute exact component={LandingScreen} path="/transactions" />
      {/* <ProtectedRoute
        path="/audit/transaction/:extTxnId"
        component={TransactionAuditScreen}
      /> */}
      <ProtectedRoute exact path="/audit" component={TransactionAuditScreen} />
      <ProtectedRoute exact path="/import" component={ImportScreen} />
      {/* this is for Stripe */}
      <ProtectedRoute exact path="/checkout" component={StripeCheckout} />
      <ProtectedRoute
        exact
        path="/checkout/success"
        component={StripeSuccess}
      />
      <ProtectedRoute exact path="/checkout/cancel" component={StripeCancel} />
      <ProtectedRoute exact path="/feedback" component={FeedbackScreen} />
      {/* <Route component={TradesScreen} /> */}
      {/* default catch-all react-router */}
      {/* catch-all for unmatched requests */}
      <CoinbaseAuthRouteWrapper />

      {/* onboarding */}

      <Redirect
        to={{
          pathname: "/",
          // state: { referrer: location },
        }}
      />
      {/* <Route path="/about">
            <About />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route> */}
    </Switch>
  );
};
