import {
  CogIcon,
  HomeIcon,
  CalculatorIcon,
  BoltIcon,
  ChatBubbleLeftRightIcon,
  CalendarIcon,
  ChartPieIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  UsersIcon,
  UserCircleIcon,
  QueueListIcon,
  LightBulbIcon,
  LockClosedIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import { FEATURE_FLAG_KEYS } from "@moonscape/shared";

// Define the navigation item type
export interface NavigationItem {
  name: string;
  href: string;
  icon: any;
  featureFlag?: string; // Optional feature flag key
  roles?: string[]; // Optional roles for role-based access
}

// Base navigation items
/**
 * update DesktopSidebar.tsx to use this navigation
 * and MobileMenu.tsx to use this navigation for feature flags
 */
const baseNavigation: NavigationItem[] = [
  { name: "Quickstart", href: "/quickstart", icon: BoltIcon },
  { name: "Dashboard", href: "/", icon: HomeIcon },
  { name: "Portfolio", href: "/portfolio", icon: ChartBarIcon },
  { name: "Tax Center", href: "/tax/cgt", icon: ChartPieIcon },
  {
    name: "Tax Safe Harbor",
    href: "/tax/safe-harbor",
    icon: ChartPieIcon,
    featureFlag: FEATURE_FLAG_KEYS.WEB_SAFE_HARBOR,
  },
  // { name: "Tax Pro", href: "/tax/pro", icon: BanknotesIcon },
  // { name: "Settings", href: "/settings/exchange", icon: CogIcon },
  // { name: "CSV Upload", href: "/import", icon: ArrowUpTrayIcon },

  // { name: "Feedback", href: "/feedback", icon: ChatBubbleLeftRightIcon },
  // { name: "Logout", href: "/logout", icon: LogoutIcon },
  {
    name: "Tax Safe Harbor",
    href: "/tax/safe-harbor",
    icon: ChartPieIcon,
    featureFlag: FEATURE_FLAG_KEYS.WEB_SAFE_HARBOR,
  },
  // { name: "Tax Pro", href: "/tax/pro", icon: BanknotesIcon },
  // { name: "Settings", href: "/settings/exchange", icon: CogIcon },
  // { name: "CSV Upload", href: "/import", icon: ArrowUpTrayIcon },

  // { name: "Feedback", href: "/feedback", icon: ChatBubbleLeftRightIcon },
  // { name: "Logout", href: "/logout", icon: LogoutIcon },
  {
    name: "Plans",
    href: "/plans",
    icon: BoltIcon,
    featureFlag: FEATURE_FLAG_KEYS.WEB_SHOW_IAP,
  },
  // Add other navigation items...
];

// Navigation builder function
export const buildNavigation = (
  featureFlags: Record<string, boolean> = {},
  userRoles: string[] = []
) => {
  return baseNavigation.filter((item) => {
    // Check feature flags
    if (item.featureFlag && !featureFlags[item.featureFlag]) {
      return false;
    }

    // Check roles if needed
    if (item.roles && !item.roles.some((role) => userRoles.includes(role))) {
      return false;
    }

    return true;
  });
};

export default baseNavigation;
