import React from "react";
import { Link } from "react-router-dom";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

interface QuickStartStepProps {
  title: string;
  isCompleted?: boolean;
  content: React.ReactNode;
  value: string;
  onNext?: () => void;
  isLastStep?: boolean;
}

const QuickStartStep: React.FC<QuickStartStepProps> = ({
  title,
  isCompleted = false,
  content,
  value,
  onNext,
  isLastStep = false,
}) => (
  <AccordionItem value={value}>
    <AccordionTrigger className="hover:no-underline">
      <div
        className={`w-full flex items-center gap-3 ${
          isCompleted ? "text-green-700" : "text-gray-900"
        }`}
      >
        <div
          className={`w-6 h-6 rounded-full flex items-center justify-center
          ${isCompleted ? "bg-green-100" : "bg-gray-100"}`}
        >
          {isCompleted ? "✓" : ""}
        </div>
        <span className="text-lg font-medium">{title}</span>
      </div>
    </AccordionTrigger>
    <AccordionContent className="px-9">
      {content}
      {!isLastStep && (
        <div className="mt-4 flex justify-end">
          <button
            onClick={(e) => {
              e.preventDefault();
              onNext?.();
            }}
            className="inline-flex items-center px-4 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors"
          >
            Next
            <svg
              className="ml-2 w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      )}
    </AccordionContent>
  </AccordionItem>
);

export const QuickStartScreen: React.FC = () => {
  const steps = [
    {
      value: "connect",
      title: "Connect an Exchange or Import CSV Data",
      content: (
        <div className="text-gray-600">
          <p>
            Link your exchange accounts via API keys or manually upload a CSV of
            your transactions.
          </p>
          <a
            href="/settings/exchange"
            className="text-teal-600 font-semibold hover:text-teal-700 underline underline-offset-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to Exchange Connections
          </a>
        </div>
      ),
    },
    {
      value: "tax-country",
      title: "Set Your Tax Country (optional) ",
      content: (
        <div className="text-gray-600">
          <p>
            Choose your tax jurisdiction to apply the correct tax rules.
            Required if you want to see your tax calculations.
          </p>
          <a
            href="/settings/tax"
            className="text-teal-600 font-semibold hover:text-teal-700 underline underline-offset-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Set Tax Country
          </a>
        </div>
      ),
    },
    {
      value: "tax-reports",
      title: "See all your crypto transactions in one place",
      content: (
        <div className="text-gray-600">
          <p>
            Your crypto transactions are now imported and synced automatically.
            You can search and filter your trades, track balances, and ensure
            your portfolio stays up to date.
          </p>
          <p className="mt-2">
            When you're ready, generate tax reports to see your gains, losses,
            and estimated tax liability.
          </p>
          <div className="mt-4 space-y-2">
            <a
              href="/"
              className="text-teal-600 font-semibold hover:text-teal-700 underline underline-offset-4 block"
            >
              View & Search Trades
            </a>
            <a
              href="/tax/cgt"
              className="text-teal-600 font-semibold hover:text-teal-700 underline underline-offset-4 block"
            >
              See Your Taxes
            </a>
          </div>
        </div>
      ),
    },
  ];

  const [activeStep, setActiveStep] = React.useState<string | undefined>(
    steps[0].value
  );

  const handleNext = (currentValue: string) => {
    const currentIndex = steps.findIndex((step) => step.value === currentValue);
    if (currentIndex < steps.length - 1) {
      setActiveStep(steps[currentIndex + 1].value);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-2">Quickstart</h1>
        <p className="text-gray-600 text-lg">
          Getting started with Moonscape is easy
        </p>
      </div>

      <div className="bg-white rounded-xl p-6 mb-8 border border-gray-200">
        <div className="flex items-start gap-4">
          <div className="flex-shrink-0">
            <ChatBubbleLeftIcon className="w-12 h-12 text-teal-500" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              Need help?
            </h2>

            <p className="text-gray-600 mb-4">
              Shoot us a message, email{" "}
              <a
                href="mailto:hello@moonscape.app?subject=Moonscape feedback: "
                className="group inline-flex gap-x-3 rounded-md text-md font-semibold leading-6 text-gray-600 hover:bg-gray-700 hover:text-white" // Updated hover color to gray-700
              >
                <span className="font-semibold">hello@moonscape.app</span>
              </a>
            </p>
            {/* <button className="bg-teal-600 text-white px-4 py-2 rounded-lg hover:bg-teal-700 transition-colors">
              Message Us
            </button> */}
          </div>
        </div>
      </div>

      <Accordion
        type="single"
        collapsible
        className="space-y-4"
        value={activeStep}
        onValueChange={setActiveStep}
      >
        {steps.map((step, index) => (
          <QuickStartStep
            key={step.value}
            value={step.value}
            title={step.title}
            content={step.content}
            onNext={() => handleNext(step.value)}
            isLastStep={index === steps.length - 1}
          />
        ))}
      </Accordion>

      <div className="mt-8 text-gray-600">
        <p>
          Not ready to connect your exchanges? Check out our{" "}
          <Link
            to="/"
            className="text-teal-600 font-semibold hover:text-teal-700 underline underline-offset-4"
          >
            Demo Mode
          </Link>{" "}
          to see Moonscape in action!
        </p>
      </div>
    </div>
  );
};

export default QuickStartScreen;
