import React, { useEffect, useState } from "react";
import { useProfile } from "@moonscape/shared";
import { SelectionComponent } from "../SelectionComponent";

interface TaxCGTRateSelectProps {
  handleSelect: (value: string | number) => void;
  onSuccess?: () => void;
}

export const TaxCGTRateSelect: React.FC<TaxCGTRateSelectProps> = ({
  handleSelect,
  onSuccess,
}) => {
  const { profile } = useProfile();
  // const currentYear = new Date().getFullYear();
  const currentYear = 2024;
  const [currentRate, setCurrentRate] = useState<number | null>(null);

  // Update current rate when profile changes
  useEffect(() => {
    const rate = profile?.taxRates?.find(
      (rate) => rate.year === currentYear
    )?.rate;
    if (rate !== undefined) {
      setCurrentRate(rate);
    }
  }, [profile?.taxRates, currentYear]);

  console.log(
    "[CGT] Current rate:",
    currentRate,
    "Profile tax rates:",
    profile?.taxRates
  );

  return (
    <SelectionComponent
      options={[
        { value: "18", label: "18% (Basic rate)" },
        { value: "24", label: "24% (Higher rate)" },
      ]}
      selectedValue={currentRate}
      handleSelect={(event) => {
        const value = event.target.value;
        setCurrentRate(value); // Update local state immediately
        handleSelect(Number(value));
        onSuccess?.();
      }}
    />
  );
};
